import React, { useRef } from 'react'
import Button from '@components/common/Button'
import { useRouter } from 'next/router'
import DangerouslySetHtml from '@libs/parser/components/DangerouslySetHtml'
import useAppContext from '@hooks/useAppContext'

interface ISectionWrapper {
  title: string
  children: JSX.Element | JSX.Element[] | null
  heightClass?: string
  body?: string
  link?: string
  hasHalfIndentation?: boolean
}

const SectionWrapper = ({
  title,
  body,
  link,
  children,
  hasHalfIndentation,
  heightClass, // if the children is a GridGallery without noCardsLimit prop, avoid setting a heightClass prop because it is unknown how many items will be displayed
}: ISectionWrapper): JSX.Element => {
  const router = useRouter()
  const { isScreenReady } = useAppContext()
  const bodyParentRef = useRef(null)
  return (
    <div
      className={`desktop:m-2 mb-[2.375rem] desktop:mb-[3rem] ${heightClass}`}
    >
      <div className='mx-5 flex items-start justify-between desktop:mx-0 '>
        <div className='w-full mx-auto desktop:max-w-[1376px]'>
          <h2 className='mt-4 mb-2 text-h2 font-semibold desktop:mt-6 desktop:mb-3'>
            {title || ' '}
          </h2>
          <div className='pb-5 text-p text-darkgray' ref={bodyParentRef}>
            {body ? (
              <DangerouslySetHtml html={body} parentRef={bodyParentRef} />
            ) : (
              ' '
            )}
          </div>
        </div>
      </div>
      <div className={`${hasHalfIndentation ? 'w-full' : ''}`}>
        {isScreenReady ? children : <div>&nbsp;</div>}
      </div>
      {isScreenReady && link && (
        <div
          className={`mx-auto flex w-full max-w-[400px] justify-center px-5 desktop:px-0 mt-[2rem]`}
        >
          <Button
            variant='tertiary'
            onClick={(): void => {
              router.push(link).catch(() => {})
            }}
          >
            Explore All
          </Button>
        </div>
      )}
    </div>
  )
}

export default SectionWrapper
