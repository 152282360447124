import { FiltersContext } from 'providers/FiltersProvider'
import { useContext } from 'react'
import SearchMgtCarousel from '@components/SearchManagement/SearchMgtCarousel'

const SearchMgtHome = (): JSX.Element => {
  const filtersContext = useContext(FiltersContext)
  return (
    <div className='bg-blue-5 pt-[2rem] pb-3 desktop:pt-[2.5rem] desktop:pb-[2rem]'>
      <div className='scaling mx-auto px-5 desktop:mb-4 desktop:h-auto desktop:w-[82rem] desktop:max-w-[1300px]'>
        <div className='desktop:h-auto'>
          <h2 className='mb-1 text-h6 font-semibold'>
            {filtersContext?.searchManagement?.Experiences_Long_Title}
          </h2>
        </div>
        <div>
          <div className='overflow-visible desktop:mb-0 desktop:pb-0'>
            <SearchMgtCarousel
              carouselData={
                filtersContext?.searchManagement?.Experiences_Filter
              }
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SearchMgtHome
