
interface IViewPhotosButton {
  onClick: () => void;
}

const ViewPhotosButton = ({ onClick }: IViewPhotosButton): JSX.Element => {
  return (
    <button
      className="h-[2.25rem] rounded-full bg-orange-10 px-4 text-p13 font-semibold text-orange hover:bg-orange-20 desktop:pt-1"
      onClick={onClick}
    >
      View Photos
    </button>
  );
};

export default ViewPhotosButton;
