import { STATIC_LANDING_PAGES_URLS } from '@components/utils/constants'
import { sanitizeTags } from '@components/utils/sanitizeHelpers'
import { sortPackagesAndLocations } from '@components/utils/sortingHelpers'
import { IPackageResponse } from '@customTypes/modelResponses'
import useAppContext from '@hooks/useAppContext'
import Card from '@libs/cards/Card'
import CardWrapper from '@libs/cards/CardWrapper'
import GridGallery from '@libs/slider/GridGallery'
import SectionWrapper from '@modules/homePage/SectionWrapper'
import { useMemo } from 'react'

interface IHomePackages {
  title: string
  body: string
  packages: IPackageResponse[]
}

function HomePackages({ title, body, packages }: IHomePackages): JSX.Element {
  const { isMobile, isTablet, isDesktop, screenTypeVariant } = useAppContext()

  const cardComponents = useMemo(() => {
    return sortPackagesAndLocations(packages).map((pack, index) => {
      return (
        <CardWrapper key={pack?.id} widthPercentage={80}>
          <Card
            id={pack?.id}
            title={pack?.Title}
            blurb={pack?.Blurb}
            url={`/packages/${pack?.SEO?.Slug}`}
            tags={sanitizeTags(pack?.tags ? pack.tags : [])}
            image={pack?.PrimaryImage?.[0]}
            imageSize={isMobile ? 'medium' : 'small'}
            imageLoadingPriority={false}
          />
        </CardWrapper>
      )
    })
  }, [packages, isMobile])

  const numItems = cardComponents.length

  const sectionContent = useMemo(() => {
    if (!numItems) return null
    return (
      <GridGallery
        components={cardComponents}
        moreWhat='Combos'
        responsiveGridConfig={{
          mobile: {
            itemHeight: 540,
            itemsPerRow: 1,
          },
          tablet: {
            itemHeight: 480,
            itemsPerRow: 2,
          },
          desktop: {
            itemHeight: 430,
            itemsPerRow: 3,
          },
        }}
      />
    )
  }, [numItems, cardComponents])

  return (
    <SectionWrapper
      title={title}
      body={body}
      link={STATIC_LANDING_PAGES_URLS.BEST}
    >
      {sectionContent}
    </SectionWrapper>
  )
}

export default HomePackages
