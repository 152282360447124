import GradientCover from '@components/common/GradientCover'
import ImageCDN from '@components/skeleton/ImageCDN'
import { ImageDefault } from '@components/utils/Utils'
import { onEnterDown } from '@components/utils/accessibilityHelpers'
import { TAG_TYPES } from '@components/utils/constants'
import { IImageComponentResponse } from '@customTypes/componentResponses'
import { FiltersContext } from 'providers/FiltersProvider'
import { useContext } from 'react'

interface ISearchMgtCard {
  id: number
  name: string
  image?: IImageComponentResponse
}

const SearchMgtCard = ({ id, name, image }: ISearchMgtCard): JSX.Element => {
  const filtersContext = useContext(FiltersContext)

  const clickHandler = (): void => {
    filtersContext?.selectExperience({
      id,
      title: name,
      isChecked: true,
      type: TAG_TYPES.TAG,
    })
    filtersContext?.closeAccordion(0)
    filtersContext?.openFilterModal(1)
  }

  return (
    <div
      className='mt-4 mb-2 mr-4 cursor-pointer relative mb-0'
      tabIndex={0}
      onKeyDown={(e) => onEnterDown(e, clickHandler)}
    >
      <div className='relative w-[6rem] h-[9rem] tablet:w-[8rem] tablet:h-[12rem] desktop:w-[16.688rem] desktop:h-[29.375rem]'>
        <ImageCDN
          className='rounded-2xl'
          src={ImageDefault(image)}
          alt='w'
          layout='fill'
          objectFit='cover'
          width={267}
          height={470}
        />
        <h3 className='absolute z-[2] font-medium bottom-1 flex w-full justify-center px-[10px] pb-[10px] text-center text-p13 leading-4 text-white desktop:bottom-4 desktop:text-h4'>
          {name}
        </h3>
        <GradientCover rounded={true} opacity={80} />
      </div>
    </div>
  )
}

export default SearchMgtCard
