import Chip from "@components/SearchManagement/Chip";
import { ITag } from "@modules/requestAQuote/components/TripPreferences";
import React, { useMemo, useRef, useState } from "react";
import useIsomorphicLayoutEffect from '@hooks/useIsomorphicLayoutEffect'

interface IchipElement extends Element {
  offsetTop?: number;
}

interface IUseChips {
  tags?: ITag[];
}

interface IUseChipsReturnType {
  chipsContainer: React.RefObject<HTMLDivElement>;
  chipsElements: JSX.Element;
}


const useCardChips = ({ tags }: IUseChips): IUseChipsReturnType => {
  const [numberOfItemsDisplayed, setNumberOfItemsDisplayed] =
    useState<number>();

  const chipsContainer = useRef<HTMLDivElement>(null);
  const chipsElements = useMemo(() => {
    return (
      <>
        {tags?.slice(0, numberOfItemsDisplayed).map((tag) => {
          return (
            <Chip
              key={tag.id}
              item={{
                title: tag.title,
              }}
              variant="white"
              onClick={undefined}
              cursorPointer
            />
          );
        })}
        {numberOfItemsDisplayed && (
          <Chip
            key={"plus_more_chip"}
            item={{
              title: "+ more",
            }}
            variant="white"
            onClick={undefined}
            cursorPointer
          />
        )}
      </>
    );
  }, [tags, numberOfItemsDisplayed]);

  useIsomorphicLayoutEffect(() => {
    const chipsCollection = chipsContainer.current?.children;
    const chipElements: IchipElement[] = chipsCollection
      ? Array.from(chipsCollection)
      : [];
    if (chipElements && chipElements.length > 0) {
      const startingOffset = chipElements[0].offsetTop;

      for (let i = 0; i < chipElements.length; i++) {
        const currentElement = chipElements[i];
        if (
          currentElement.offsetTop &&
          typeof startingOffset !== "undefined" &&
          currentElement.offsetTop > startingOffset
        ) {
          setNumberOfItemsDisplayed(i - 1);
          break;
        }
      }
    }
  }, [tags]);

  return { chipsContainer, chipsElements };
};

export default useCardChips;
