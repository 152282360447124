import Link from "next/link";
import ConditionalWrapper from "./common/ConditionalWrapper";
import ImageCDN from "./skeleton/ImageCDN";

interface ITagWithPhoto {
  tag?: string;
  image?: string;
  slug?: string;
}

const TagWithPhoto = ({ tag, image, slug }: ITagWithPhoto): JSX.Element => {
  return (
    <div
      className="flex-mobile flex-col gap-2 desktop:flex desktop:flex-row desktop:items-center desktop:gap-5 desktop:pb-9">
      <ConditionalWrapper
        condition={!!slug}
        wrapper={(children) => (
          <Link
            href={`/${slug}`}
            prefetch={false}
            className="flex cursor-pointer flex-col gap-2 desktop:flex-row desktop:items-center desktop:gap-5 desktop:pr-8"
          >
            {children}
          </Link>
        )}
      >
        <>
          <div className="jusify-center relative h-24 desktop:w-24 desktop:flex-row desktop:gap-5">
            <ImageCDN
              className="rounded-2xl"
              layout="fill"
              objectFit="cover"
              src={image as string}
              height="120"
              width="240"
              alt={tag}
            />
          </div>
          <h3 className="text-h6 font-medium desktop:w-[164px]">{tag}</h3>
        </>
      </ConditionalWrapper>
    </div>
  );
};

export default TagWithPhoto;
