import React from 'react'
//import UniqueSellingPoints from "@components/UniqueSellingPoints";
import { HOME_PAGE_DETAILED, TAG_PACKAGE_THEME } from '@components/home/routes'
import baseApiCalls from '@services/baseApiCalls'
import { GetStaticProps } from 'next'
import { IHomePageResponse } from '@customTypes/commonResponses'
import { IPackageThemeResponse, IReviewResponse, } from '@customTypes/modelResponses'
import fetchGet from '@services/api/fetchGet'
import MainWrapper from '@components/pages/common/MainWrapper'
import HomeHero from '@modules/homePage/HomeHero'
import { JSON_SCHEMAS } from '@components/utils/constants'
import JsonLdSchema from '@libs/schema/JsonLdSchema'
import HomePackages from '@modules/homePage/sections/HomePackages'
import styledParser, { applyStyledParser } from '@libs/parser/styled-parser'
import HomeExperts from '@modules/homePage/sections/HomeExperts'
import HomeReviews from '@modules/homePage/sections/HomeReviews'
import SearchMgtHome from '@components/SearchManagement/SearchMgtHome'
import HomeDestinations from '@modules/homePage/sections/HomeDestinations'
import UniqueSellingPoints from '@components/UniqueSellingPoints'
import HomeAboutUs from '@modules/homePage/sections/HomeAboutUs'
import HomeAccommodations from '@modules/homePage/sections/HomeAccommodations'
import HomePackageThemes from '@modules/homePage/sections/HomePackageThemes'
import SectionsContainer from '@modules/homePage/SectionsContainer'

interface IHomePage {
  homePage: IHomePageResponse
  packageTheme: IPackageThemeResponse[]
}

export default function Home({
                               homePage,
                               packageTheme,
                             }: IHomePage): JSX.Element {
  return (
    <>
      <JsonLdSchema
        data={{ breadcrumbObjects: undefined }}
        type={JSON_SCHEMAS.LOCAL_BUSINESS}
      />
      <MainWrapper>
        <HomeHero
          title={homePage.Slogan}
          eyebrow={homePage.Eyebrow}
          image={homePage.Hero_Media?.[0]}
        />
        <SectionsContainer>
          <HomePackages
            title={homePage.Packages_Section_Headline}
            body={homePage.Packages_Section_Text}
            packages={homePage.packages}
          />
        </SectionsContainer>
        <UniqueSellingPoints usp={homePage.Unique_Selling_Point}/>
        <SectionsContainer>
          <HomePackageThemes
            title={homePage.PackageThemes_Section_Headline}
            tags={packageTheme}
          />
        </SectionsContainer>
        <SearchMgtHome/>
        <SectionsContainer>
          <HomeExperts
            title={homePage.TCs_Section_Headline}
            text={homePage.TCs_Section_Text}
            experts={homePage.travel_consultants}
          />
          <HomeReviews
            title={homePage.Reviews_Section_Headline}
            section={homePage.Reviews_Section_Text}
            reviews={homePage.reviews}
          />
          <HomeDestinations
            locations={homePage.locations}
            title={homePage.Destinations_Section_Headline}
            body={homePage.Destinations_Section_Text}
          />
        </SectionsContainer>
        <HomeAboutUs
          key={homePage.About_Us.id}
          title={homePage.About_Us.AboutUsHeadline}
          text={homePage.About_Us.AboutUsText}
          image={
            homePage?.About_Us?.AboutUsMedia &&
            homePage.About_Us.AboutUsMedia[0]
          }
        />
        <SectionsContainer noMarginTopDesktop>
          <HomeAccommodations
            accommodations={homePage.accommodations}
            title={homePage.Accommodations_Section_Headline}
            body={homePage.Accommodations_Section_Text}
          />
        </SectionsContainer>
      </MainWrapper>
    </>
  )
}

export const getStaticProps: GetStaticProps = async () => {
  const homePageData = await fetchGet(HOME_PAGE_DETAILED)
  const homePage: IHomePageResponse = await homePageData.json()
  const packageThemeData = await fetchGet(TAG_PACKAGE_THEME)
  const packageTheme = await packageThemeData.json()

  const {
    footer,
    mediaAssets,
    searchManagementRes,
    topNavigation,
    menus,
    usp,
  } = await baseApiCalls()
  const pageType = 1

  // Style CRM content:
  homePage.Eyebrow = styledParser(homePage.Eyebrow, {
    skipParagraphStyling: true,
    isHeroEyebrow: true,
  })
  applyStyledParser(homePage, [
    'Slogan',
    'Packages_Section_Headline',
    'Packages_Section_Text',
    'Accommodations_Section_Text',
    'Destinations_Section_Text',
    'TCs_Section_Text',
    'Reviews_Section_Text',
  ])
  applyStyledParser(homePage.About_Us, ['AboutUsHeadline', 'AboutUsText'])

  homePage.reviews = homePage.reviews.map((review: IReviewResponse) => ({
    ...review,
    ReviewText: styledParser(review.ReviewText),
  }))

  return {
    props: {
      footer,
      topNavigation,
      mediaAssets,
      homePage,
      packageTheme,
      pageType,
      menus,
      usp,
      searchManagement: searchManagementRes,
    },
  }
}
