interface IMAGE_SIZE_Map {
  [key: string]: {
    css: string;
    size: {
      height: number;
      width: number;
    };
  };
}

export const IMAGE_SIZE: IMAGE_SIZE_Map = {
  xxl: { css: "h-[40rem]", size: { height: 640, width: 1280 } },
  xl: { css: "h-[30rem]", size: { height: 480, width: 960 } },
  large: { css: "h-[24rem]", size: { height: 384, width: 768 } },
  semilarge: { css: "h-[22rem]", size: { height: 352, width: 704 } },
  medium: { css: "h-[20rem]", size: { height: 320, width: 640 } },
  semimedium: { css: "h-[17.5rem]", size: { height: 280, width: 560 } },
  small: { css: "h-[15rem]", size: { height: 240, width: 480 } },
  xsmall: { css: "h-[11.25rem]", size: { height: 180, width: 360 } },
  default: { css: "h-[15rem]", size: { height: 240, width: 480 } },
};

export const CARD_IMAGE_WIDTH = {
  SMALL: 420,
  MEDIUM: 640,
  LARGE: 820,
  EXTRA_LARGE: 1280,
};

export type CardTypeType =
  | "package"
  | "location"
  | "accommodation"
  | "activity";
