import React, { useRef } from "react";
import { IImageComponentResponse, IMediaComponentResponse, } from "../types/componentResponses";
import ConditionalWrapper from "./common/ConditionalWrapper";
import ImageCDN from "./skeleton/ImageCDN";
import DangerouslySetHtml from '@libs/parser/components/DangerouslySetHtml'
import { ImageDefault } from '@components/utils/Utils'

interface IHeroFullWidth {
  image: string | IMediaComponentResponse | undefined | IImageComponentResponse;
  title: string;
  subtitle?: string;
  eyebrow?: string | null;
  isEyebrowRichText?: boolean;
}

const HeroFullWidth = ({
                         image,
                         title,
                         subtitle,
                         eyebrow,
                         isEyebrowRichText,
                       }: IHeroFullWidth): JSX.Element => {

  const titleParentRef = useRef(null);
  const eyebrowParentRef = useRef(null);

  return (
    <div className="relative w-[100vw] h-[calc(100vh-72px)] max-h-[684px] desktop:h-[calc(100vh-58px)] desktop:max-h-[100%]">
      <div
        className="absolute w-full h-full z-[1]"
        style={{ background: 'linear-gradient(90deg, #000000 -14.21%, rgba(0, 0, 0, 0) 58.05%)' }}/>
      <ImageCDN
        src={ImageDefault(image)}
        alt="w"
        quality={72}
        objectFit={'cover'}
        fetchPriority={'high'}
        isHero
      />
      <ConditionalWrapper
        condition={true}
        wrapper={(children) => {
          return (
            <div className="absolute bottom-[160px] z-[2] w-full desktop:bottom-[240px]">
              {children}
            </div>
          );
        }}
      >
        <div className="text-white mx-auto w-full px-[16px] text-center">
          <div className="flex flex-col gap-[32px]">
            <h1 className={`text-herotitle text-white font-bold text-shadow-sm`} ref={titleParentRef}>
              Custom Vacations to Costa Rica
            </h1>

            <h2 className={`text-white font-bold text-shadow-sm`} ref={titleParentRef}>
              Expertly planned by local travel agents since 1999
            </h2>
            {eyebrow && (
              <div className="text-center text-p text-white" ref={eyebrowParentRef}>
                {isEyebrowRichText && typeof eyebrow === 'string'
                  ? DangerouslySetHtml({ html: eyebrow, parentRef: eyebrowParentRef })
                  : eyebrow}
              </div>
            )}
          </div>
        </div>
      </ConditionalWrapper>
    </div>
  );
};

export default HeroFullWidth;