import { IReviewResponse } from '@customTypes/modelResponses'
import CardWrapper from '@libs/cards/CardWrapper'
import ReviewCard, { ReviewCardSliderWrapper } from '@libs/cards/ReviewCard'
import GridGallery from '@libs/slider/GridGallery'
import SectionWrapper from '@modules/homePage/SectionWrapper'
import { STATIC_LANDING_PAGES_URLS } from '../../../components/utils/constants'
import React, { useMemo } from 'react'

interface IHomeReviews {
  title: string
  section: string
  reviews: IReviewResponse[]
}

const HomeReviews = ({
  title,
  section,
  reviews,
}: IHomeReviews): JSX.Element => {
  const cardComponents = useMemo(() => {
    return reviews.map((review) => {
      return (
        <CardWrapper key={review?.id} widthPercentage={100}>
          <ReviewCard
            review={review?.ReviewText}
            country={review?.Country}
            date={review?.Date}
            reviewer={review?.ReviewerName}
            images={review?.Reviewer_Image}
            headshot={review?.Headshot}
            hasGallery={true}
          />
        </CardWrapper>
      )
    })
  }, [reviews])

  return (
    <div className='relative'>
      <SectionWrapper
        title={title}
        body={section}
        link={STATIC_LANDING_PAGES_URLS.REVIEWS}
        hasHalfIndentation
      >
        <ReviewCardSliderWrapper leftOffset='10px'>
          <GridGallery
            components={cardComponents}
            moreWhat='Reviews'
            responsiveGridConfig={{
              mobile: {
                itemHeight: 670,
                itemsPerRow: 1,
              },
            }}
          />
        </ReviewCardSliderWrapper>
      </SectionWrapper>
    </div>
  )
}

export default HomeReviews
