import { FILTER } from '@components/utils/constants'
import { IQueryItemExperience } from '@customTypes/filtersProvider'
import GridGallery from '@libs/slider/GridGallery'
import { useMemo } from 'react'
import SearchMgtCard from './SearchMgtCard'

interface ISearchMgtCarousel {
  carouselData?: Array<{ id: number; tag: IQueryItemExperience }>
}

const SearchMgtCarousel = ({
  carouselData,
}: ISearchMgtCarousel): JSX.Element => {
  const cardComponents = useMemo(() => {
    return carouselData?.map((card, index) => (
      <div key={card[FILTER.EXPERIENCE.strapiName].id}>
        <SearchMgtCard
          key={card[FILTER.EXPERIENCE.strapiName].id}
          id={card[FILTER.EXPERIENCE.strapiName].id}
          name={card[FILTER.EXPERIENCE.strapiName].title || ''}
          image={card[FILTER.EXPERIENCE.strapiName].Media}
        />
      </div>
    ))
  }, [carouselData])

  return (
    <div className='overflow-visible desktop:m-0'>
      <GridGallery
        components={cardComponents}
        moreWhat='Results'
        responsiveGridConfig={{
          desktop: {
            itemsPerRow: 4,
            itemHeight: 490,
          },
          desktopSmall: {
            itemsPerRow: 4,
            itemHeight: 220,
          },
          tablet: {
            itemsPerRow: 4,
            itemHeight: 220,
          },
          mobile: {
            itemsPerRow: 4,
            itemHeight: 180,
          },
        }}
      />
    </div>
  )
}

export default SearchMgtCarousel
