import HeroFullWidth from "@components/HeroFullWidth";
import OpenGraphImage from "@components/OpenGraphImage";
import { IMediaComponentResponse } from "@customTypes/componentResponses";

interface IHomeTitle {
  title: string;
  subtitle?:string;
  eyebrow?: string | null;
  image?: IMediaComponentResponse;
}

const HomeHero = ({ title, subtitle, eyebrow, image }: IHomeTitle): JSX.Element => {
  return (
    <>
      <OpenGraphImage image={image} alt={title} ></OpenGraphImage>
      <div className="desktop:mt-[58px]" >
        <HeroFullWidth
          title={title}
          subtitle={subtitle}
          eyebrow={eyebrow}
          image={image}
          isEyebrowRichText={true}
        />
      </div>
    </>
  );
};
export default HomeHero;