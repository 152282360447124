import TCBioCard from '@components/TravelConsultants/TCBioCard'
import { STATIC_LANDING_PAGES_URLS } from '@components/utils/constants'
import { ITravelConsultantResponse } from '@customTypes/modelResponses'
import CardWrapper from '@libs/cards/CardWrapper'
import GridGallery from '@libs/slider/GridGallery'
import SectionWrapper from '@modules/homePage/SectionWrapper'
import { useMemo } from 'react'

interface IHomeExperts {
  title: string
  text: string
  experts: ITravelConsultantResponse[]
}

const HomeExperts = ({ title, text, experts }: IHomeExperts): JSX.Element => {
  const cardComponents = useMemo(() => {
    return experts.map((expert, index) => (
      <CardWrapper key={expert?.id} widthPercentage={75}>
        <TCBioCard
          image={
            expert.Media?.PrimaryImage && expert.Media.PrimaryImage.length > 0
              ? expert.Media?.PrimaryImage[0]
              : null
          }
          name={expert?.Name}
          slug={expert?.SEO?.Slug}
          blurb={expert?.Blurb}
          specialties={expert?.tags}
        />
      </CardWrapper>
    ))
  }, [experts])

  return (
    <SectionWrapper
      title={title}
      body={text}
      link={STATIC_LANDING_PAGES_URLS.EXPERTS}
    >
      <GridGallery
        components={cardComponents}
        moreWhat='Experts'
        responsiveGridConfig={{
          mobile: {
            itemHeight: 460,
            itemsPerRow: 1,
          },
          desktopSmall: {
            itemHeight: 440,
            itemsPerRow: 3,
          },
          desktop: {
            itemHeight: 460,
            itemsPerRow: 4,
          },
        }}
      />
    </SectionWrapper>
  )
}

export default HomeExperts
