import ImageCDN from '@components/skeleton/ImageCDN'
import React, { useRef } from 'react'
import { ImageDefault } from '@components/utils/Utils'
import { STATIC_LANDING_PAGES_URLS } from '@components/utils/constants'
import { IImageComponentResponse } from '@customTypes/componentResponses'
import Link from 'next/link'
import { useRouter } from 'next/router'
import DangerouslySetHtml from '@libs/parser/components/DangerouslySetHtml'

interface IHomeAboutUs {
  title: string
  text: string
  image: IImageComponentResponse | null
}

const HomeAboutUs = ({ title, image, text }: IHomeAboutUs): JSX.Element => {
  const router = useRouter()
  const titleRef = useRef(null)
  const textRef = useRef(null)
  return (
    <div className='h-[1200px] tablet:h-[880px] desktopSmall:h-[800px] desktop:h-[980px]'>
      <div className={`max-w-full max-h-full`}>
        <div className='relative m-auto mt-[3.125rem] flex items-center justify-center desktop:m-auto desktop:w-[82rem] desktop:max-w-[95%]'>
          <div className='top-0 w-full items-center justify-items-center gap-20 mb-10'>
            <h2
              className='pb-5 pl-5 text-h2 font-semibold desktop:mb-4 desktop:w-[75%] desktop:p-0'
              ref={titleRef}
            >
              <DangerouslySetHtml html={title} parentRef={titleRef} />
            </h2>
            <div className='flex flex-col gap-[20px] desktopLarge:flex-row-reverse desktopLarge:justify-between'>
              <div className='desktop:flex desktop:items-center'>
                <div className='relative mx-5 h-64 desktop:mx-0 desktop:h-[363px] desktop:w-[640px]'>
                  <ImageCDN
                    className='rounded-2xl'
                    layout='fill'
                    objectFit='cover'
                    width='640'
                    height='363'
                    src={ImageDefault(image)}
                    alt='About Us Image'
                  />
                </div>
              </div>
              <div className='desktop:w-[600px]'>
                {/* Removed line-clamp */}
                {/* <p className="max-w-xs mx-5 desktop:m-0 text-darkgray line-clamp-6"> */}
                <div
                  className='mx-5 max-w-[100%] text-left text-darkgray desktop:m-0 desktop:max-w-[100%]'
                  ref={textRef}
                >
                  <DangerouslySetHtml html={text} parentRef={textRef} />
                </div>
                <div className='mt-6 hidden text-p text-orange underline decoration-orange-10 decoration-dashed underline-offset-4 hover:decoration-orange desktop:flex desktop:justify-start'>
                  <Link href={STATIC_LANDING_PAGES_URLS.ABOUT} prefetch={false}>
                    More About Us
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='mx-5'>
        <button
          onClick={(e) => {
            e.preventDefault()
            router.push(STATIC_LANDING_PAGES_URLS.ABOUT).catch(() => {})
          }}
          className='my-5 flex h-14 w-[100%] items-center justify-center rounded-full bg-orange-10 text-center font-semibold text-orange hover:bg-orange-20 desktop:hidden'
        >
          More About Us
        </button>
      </div>
    </div>
  )
}

export default HomeAboutUs
