import ImageCDN from '@components/skeleton/ImageCDN'
import { ImageDefault } from '@components/utils/Utils'
import { IImageComponentResponse } from '@customTypes/componentResponses'
import { IMAGE_SIZE } from '@libs/cards/utils/constants'
import { ITag } from '@modules/requestAQuote/components/TripPreferences'
import CardHeaderAccessories from './CardHeaderAccessories'

interface ICardHeader {
  id?: number
  title?: string
  image?: string | IImageComponentResponse | null | number
  imageSize?: string
  tags?: ITag[]
  topLeftTag?: ITag
  imageLoadingPriority?: boolean
}

const CardHeader = ({
  tags,
  topLeftTag,
  image,
  title,
  imageSize,
  imageLoadingPriority = false,
}: ICardHeader): JSX.Element => {
  const imageSizeConfig = IMAGE_SIZE[imageSize ?? 'default']
  const { width, height } = imageSizeConfig.size

  return (
    <div
      className={`relative ${imageSizeConfig.css} overflow-hidden rounded-3xl desktop:drop-shadow-lg`}
    >
      <ImageCDN
        src={ImageDefault(image as string)}
        layout='fill'
        objectFit='cover'
        alt={title}
        width={width}
        height={height}
        priority={false}
        fetchPriority={imageLoadingPriority ? 'high' : undefined}
      />
      <CardHeaderAccessories topLeftTag={topLeftTag} tags={tags} />
    </div>
  )
}

export default CardHeader
