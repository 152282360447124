import CustomHeading from "@components/common/CustomHeading";
import LocationIcon from "@components/icons/LocationIcon";
import { sanitizeBudgetNumber } from "@components/utils/sanitizeHelpers";
import { HeadingLevelType } from "../Card";

interface ICardBody {
  title?: string;
  isTitleLocation?: boolean;
  location?: string;
  blurb?: string;
  priceRate?: number;
  url?: string;
  opensNewTab?: boolean;
  linkTitle?: string;
  isHorizontal?: boolean;
  noImage?: boolean;
  headingLevel?: HeadingLevelType;
}

const CardBody = ({
  title,
  isTitleLocation,
  location,
  blurb,
  priceRate,
  linkTitle,
  isHorizontal,
  noImage,
  headingLevel,
}: ICardBody): JSX.Element => {
  return (
    <div className={isHorizontal ? "pt-6" : noImage ? "pt-2" : "px-3.5 pt-2"}>
      <div className="mb-2.5 desktop:mb-3">
        {isTitleLocation ? (
          <div className="mt-1 flex flex-row gap-1">
            <div className="h-full w-[1rem] stroke-darkblue">
              <LocationIcon />
            </div>
            <CustomHeading
              headingLevel={headingLevel}
              className="text-h6 font-medium text-darkgray"
            >
              {title}
            </CustomHeading>
          </div>
        ) : (
          <CustomHeading
            headingLevel={headingLevel}
            className="text-h4 font-medium "
          >
            {title}
          </CustomHeading>
        )}
        {location && (
          <div className="mt-1 flex flex-row gap-1">
            <div className="h-[1rem] w-[0.75rem] stroke-darkgray">
              <LocationIcon />
            </div>
            <p className="text-p13 font-medium text-darkgray">{location}</p>
          </div>
        )}
      </div>
      {blurb && (
        <p
          className={`text-p14 text-darkgray desktop:text-p ${
            linkTitle ? "mb-4" : ""
          }`}
        >
          {blurb}
        </p>
      )}
      <div className="flex flex-row justify-between">
        {linkTitle && (
          <div className="cursor-pointer text-p font-semibold text-orange underline decoration-orange-20 decoration-dashed underline-offset-4 hover:decoration-orange">
            {linkTitle}
          </div>
        )}
        {priceRate && (
          <div className="text-p13 text-darkgray">
            From
            <span className="text-h6 font-semibold text-lightblue">
              &nbsp;{sanitizeBudgetNumber(priceRate)}&nbsp;
            </span>
            / night
          </div>
        )}
      </div>
    </div>
  );
};

export default CardBody;
