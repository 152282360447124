import { STATIC_LANDING_PAGES_URLS } from '@components/utils/constants'
import { IPackageThemeResponse } from '@customTypes/modelResponses'
import SectionWrapper from '@modules/homePage/SectionWrapper'
import { useMemo } from 'react'
import TagWithPhoto from '@components/TagWithPhoto'

interface IHomePackageThemes {
  title: string
  tags: IPackageThemeResponse[]
}

const HomePackageThemes = ({
  title,
  tags,
}: IHomePackageThemes): JSX.Element => {
  // Filtering only the tags that are in Home Page category
  const filteredTags = useMemo(() => {
    const filteredTags: IPackageThemeResponse[] = []

    tags?.forEach((tag) => {
      if (tag?.tag_categories?.length) {
        tag.tag_categories.forEach((category) => {
          if (category.Name === 'Home Page') {
            filteredTags.push(tag)
          }
        })
      }
    })

    return filteredTags
  }, [tags])

  return (
    <SectionWrapper
      title={title}
      link={STATIC_LANDING_PAGES_URLS.PACKAGES}
      heightClass={`h-[793px] desktop:h-[470px]`}
    >
      <div className='mt-4 grid grid-cols-2 gap-4 px-5 desktop:grid-cols-4 desktop:gap-0 desktop:p-0'>
        {filteredTags.map((e, index) => (
          <TagWithPhoto
            key={index}
            tag={e.title}
            image={e.img}
            slug={e.landingPageSlug}
          />
        ))}
      </div>
    </SectionWrapper>
  )
}

export default HomePackageThemes
