import { STATIC_LANDING_PAGES_URLS } from '@components/utils/constants'
import { sanitizeTags } from '@components/utils/sanitizeHelpers'
import { sortAccommodations } from '@components/utils/sortingHelpers'
import { IAccommodationResponse } from '@customTypes/modelResponses'
import useAppContext from '@hooks/useAppContext'
import Card from '@libs/cards/Card'
import CardWrapper from '@libs/cards/CardWrapper'
import GridGallery from '@libs/slider/GridGallery'
import SectionWrapper from '@modules/homePage/SectionWrapper'
import { useMemo } from 'react'

interface IHomeAccommodations {
  title: string
  body: string
  accommodations: IAccommodationResponse[]
}

const HomeAccommodations = ({
  title,
  body,
  accommodations,
}: IHomeAccommodations): JSX.Element => {
  const { isMobile } = useAppContext()

  const cardComponents = useMemo(() => {
    return sortAccommodations(accommodations, true).map((accommodation) => {
      return (
        <CardWrapper key={accommodation?.id} widthPercentage={80}>
          <Card
            id={accommodation?.id}
            title={accommodation?.Title}
            subtitle={accommodation?.locations?.[0]?.Title}
            blurb={accommodation?.Blurb}
            url={accommodation?.SEO?.Slug}
            tags={sanitizeTags(accommodation?.tags ? accommodation?.tags : [])}
            image={accommodation?.Media?.PrimaryImage?.[0]}
            imageSize={isMobile ? 'medium' : 'small'}
            priceRate={accommodation?.Rate}
            tagLeftTag={{
              id: accommodation?.id,
              title: accommodation?.Property_Category,
            }}
          />
        </CardWrapper>
      )
    })
  }, [accommodations, isMobile])

  return (
    <SectionWrapper
      title={title}
      body={body}
      link={STATIC_LANDING_PAGES_URLS.ACCOMMODATIONS}
    >
      <GridGallery
        components={cardComponents}
        moreWhat='Places to Stay'
        responsiveGridConfig={{
          mobile: {
            itemHeight: 530,
            itemsPerRow: 1,
          },
          tablet: {
            itemHeight: 480,
            itemsPerRow: 2,
          },
          desktop: {
            itemHeight: 460,
            itemsPerRow: 3,
          },
        }}
      />
    </SectionWrapper>
  )
}

export default HomeAccommodations
