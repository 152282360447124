import Chip from "@components/SearchManagement/Chip";
import ViewPhotosButton from "@components/common/ViewPhotosButton";
import useCardChips from "@libs/cards/hooks/useCardChips";
import { ITag } from "@modules/requestAQuote/components/TripPreferences";

interface ICardHeaderAccesssories {
  tags?: ITag[];
  topLeftTag?: ITag;
  onGalleryClick?: () => void;
  isGalleryHeader?: boolean;
}

const CardHeaderAccessories = ({
                                 topLeftTag,
                                 tags,
                                 onGalleryClick,
                                 isGalleryHeader,
                               }: ICardHeaderAccesssories): JSX.Element => {
  const { chipsContainer, chipsElements } = useCardChips({ tags });

  return (
    <>

      {topLeftTag && (
        <div className="pointer-events-auto absolute left-3 top-3">
          <Chip
            item={{
              title: topLeftTag.title,
            }}
            variant="white"
            onClick={undefined}
            cursorPointer
          />
        </div>
      )}
      {onGalleryClick && (
        <div className="pointer-events-auto absolute left-3 top-3">
          <ViewPhotosButton onClick={onGalleryClick}/>
        </div>
      )}
      {tags && (
        <div
          ref={chipsContainer}
          className={`pointer-events-auto absolute z-[2] flex h-[1.75rem] w-full flex-wrap gap-1 px-3 ${
            onGalleryClick && "pr-8"
          } ${
            // ModalCard has initially no width and cannot place tags properly;
            isGalleryHeader && "min-w-[20.25rem]"
          } left-0 bottom-3 overflow-hidden`}
        >
          {chipsElements}
        </div>
      )}
    </>
  );
};

export default CardHeaderAccessories;
