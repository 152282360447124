import React from "react";

interface ICustomHeading {
  headingLevel?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
  children?: string;
  className?: string;
}

const CustomHeading = ({
  headingLevel = "h3",
  children,
  ...props
}: ICustomHeading): JSX.Element => {
  if (children) {
    return React.createElement(headingLevel, props, children);
  }
  return <></>;
};

export default CustomHeading;
