interface ISectionsContainer {
  children: JSX.Element | JSX.Element[]
  noMarginTopDesktop?: boolean
  className?: string
}

const SectionsContainer = ({
  children,
  noMarginTopDesktop = false,
  className,
}: ISectionsContainer): JSX.Element => {
  return (
    <div
      className={`mx-auto my-[1rem]  ${
        noMarginTopDesktop ? 'desktop:mt-[-1.5rem]' : 'desktop:mt-[2.5rem]'
      } ${className || 'desktop:w-[82rem] desktop:max-w-[100%]'}`}
    >
      {children}
    </div>
  )
}

export default SectionsContainer
