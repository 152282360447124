import ConditionalWrapper from "@components/common/ConditionalWrapper";
import CustomHeading from "@components/common/CustomHeading";
import ImageCDN from "@components/skeleton/ImageCDN";
import { ImageDefault } from "@components/utils/Utils";
import { IImageComponentResponse } from "@customTypes/componentResponses";
import { IMAGE_SIZE, } from "@libs/cards/utils/constants";
import { ITag } from "@modules/requestAQuote/components/TripPreferences";
import Link from "next/link";
import { HeadingLevelType } from "./Card";

interface ICard {
  url?: string | null;
  image?: IImageComponentResponse;
  tags?: ITag[];
  title?: string;
  subtitle?: string;
  blurb?: string;
  priceRate?: number;
  link?: string;
  linkTitle?: string;
  imageSize?: string;
  isHorizontal?: boolean;
  imageWidth?: number;
  imageHeight?: number;
  headingLevel?: HeadingLevelType;
}

const BigCard = ({
                   url,
                   image,
                   title,
                   blurb,
                   imageSize,
                   imageWidth,
                   imageHeight,
                   headingLevel,
                 }: ICard): JSX.Element => {

  return (
    <ConditionalWrapper
      condition={!!url}
      wrapper={(children) => (
        <Link
          href={url ?? ""}
          prefetch={false}
        >
          {children}
        </Link>
      )}
    >
      <div
        className={`relative w-full ${
          IMAGE_SIZE[imageSize ?? "default"].css
        } justify-between overflow-hidden rounded-3xl desktop:drop-shadow-lg`}
      >
        <div className="relative h-full w-full">
          <ImageCDN
            className="z-[0] rounded-3xl"
            src={ImageDefault(image)}
            width={imageWidth}
            height={imageHeight}
            alt="w"
            layout="fill"
            objectFit="cover"
          />
        </div>

        <div className="absolute bottom-[2.25rem] z-[2] flex w-full flex-col gap-1 px-6 desktop:bottom-[3.375rem] desktop:left-[4rem] desktop:w-[29.75rem] desktop:px-0">
          <CustomHeading
            headingLevel={headingLevel}
            className="plate-top w-fit max-w-[90%] text-h4 font-semibold text-white"
          >
            {title}
          </CustomHeading>
          <div className="plate-bottom">
            {blurb && (
              <p className="mb-3 text-p14 font-medium text-white desktop:text-p">
                {blurb}
              </p>
            )}
            <span className="text-p13 font-semibold text-white underline decoration-dashed underline-offset-4 desktop:text-p">
              Explore
            </span>
          </div>
        </div>
      </div>
    </ConditionalWrapper>
  );
};

export default BigCard;
