import ConditionalWrapper from '@components/common/ConditionalWrapper'
import { slashBeforeUrlAdder } from '@components/utils/urlHelpers'
import { ITag } from '@modules/requestAQuote/components/TripPreferences'
import Link from 'next/link'
import { useMemo } from 'react'
import CardBody from './components/CardBody'
import CardHeader from './components/CardHeader'

import { IImageComponentResponse } from '@customTypes/componentResponses'

export type HeadingLevelType = 'h2' | 'h3' | 'h4'

interface ICard {
  id: number
  url?: string
  opensNewTab?: boolean
  image?: string | IImageComponentResponse | number | null
  tags?: ITag[]
  title?: string
  isTitleLocation?: boolean
  subtitle?: string
  blurb?: string
  priceRate?: number
  tagLeftTag?: ITag
  linkTitle?: string
  imageSize?: string
  isHorizontal?: boolean
  imageLoadingPriority?: boolean
  headingLevel?: HeadingLevelType
  height?: number
}

;``
const Card = ({
  id,
  url,
  opensNewTab,
  image,
  tags,
  title,
  isTitleLocation,
  subtitle,
  blurb,
  priceRate,
  tagLeftTag,
  linkTitle,
  imageSize,
  isHorizontal,
  imageLoadingPriority,
  headingLevel,
  height,
}: ICard): JSX.Element => {
  const noImage = useMemo(() => {
    return imageSize === 'none'
  }, [imageSize])

  return (
    <ConditionalWrapper
      condition={!!url}
      wrapper={(children) => (
        <Link
          className='w-full'
          href={url ? slashBeforeUrlAdder(url) : ''}
          prefetch={false}
          target={opensNewTab ? '_blank' : undefined}
        >
          {children}
        </Link>
      )}
    >
      <div
        className={`flex ${isHorizontal ? 'flex-row gap-8' : 'flex-col'}`}
        style={height ? { height } : undefined}
      >
        {!noImage && (
          <ConditionalWrapper
            condition={isHorizontal}
            wrapper={(children) => (
              <div className='min-w-[300px]'>{children}</div>
            )}
          >
            <CardHeader
              id={id}
              title={title}
              image={image}
              tags={tags}
              topLeftTag={tagLeftTag}
              imageSize={imageSize}
              imageLoadingPriority={imageLoadingPriority}
            />
          </ConditionalWrapper>
        )}

        <CardBody
          title={title}
          isTitleLocation={isTitleLocation}
          location={subtitle}
          blurb={blurb}
          priceRate={priceRate}
          linkTitle={linkTitle}
          noImage={noImage}
          headingLevel={headingLevel}
        />
      </div>
    </ConditionalWrapper>
  )
}

export default Card
