import { STATIC_LANDING_PAGES_URLS } from '@components/utils/constants'
import { sortPackagesAndLocations } from '@components/utils/sortingHelpers'
import { ILocationResponse } from '@customTypes/modelResponses'
import useAppContext from '@hooks/useAppContext'
import BigCard from '@libs/cards/BigCard'
import Card from '@libs/cards/Card'
import CardWrapper from '@libs/cards/CardWrapper'
import { CARD_IMAGE_WIDTH } from '@libs/cards/utils/constants'
import GridGallery from '@libs/slider/GridGallery'
import SectionWrapper from '@modules/homePage/SectionWrapper'
import { useMemo } from 'react'

interface IHomeDestinations {
  title: string
  body: string
  locations: ILocationResponse[]
}

function HomeDestinations({
  title,
  body,
  locations,
}: IHomeDestinations): JSX.Element {
  const { isMobile } = useAppContext()

  const cardComponents = useMemo(() => {
    return sortPackagesAndLocations(locations, 'locationWithoutLikes').map(
      (location) => {
        return (
          <CardWrapper key={location?.id}>
            {isMobile ? (
              <BigCard
                title={location?.Title}
                blurb={location?.Blurb}
                url={location?.SEO?.Slug}
                image={location?.Media?.PrimaryImage?.[0]}
                imageSize={'xxl'}
                imageWidth={CARD_IMAGE_WIDTH.LARGE}
                imageHeight={640}
              />
            ) : (
              <Card
                id={location?.id}
                title={location?.Title}
                blurb={location?.Blurb}
                url={location?.SEO?.Slug}
                image={location?.Media?.PrimaryImage?.[0]}
                imageSize={'medium'}
              />
            )}
          </CardWrapper>
        )
      },
    )
  }, [locations, isMobile])

  return (
    <SectionWrapper
      title={title}
      body={body}
      link={STATIC_LANDING_PAGES_URLS.LOCATIONS}
      hasHalfIndentation
    >
      <div>
        <GridGallery
          components={cardComponents}
          moreWhat='Destinations'
          responsiveGridConfig={{
            mobile: {
              itemHeight: 660,
              itemsPerRow: 1,
            },
            tablet: {
              itemHeight: 480,
              itemsPerRow: 2,
            },
            desktop: {
              itemHeight: 460,
              itemsPerRow: 2,
            },
          }}
        />
      </div>
    </SectionWrapper>
  )
}

export default HomeDestinations
