import { ImageDefault } from "@components/utils/Utils";
import { IImageComponentResponse, IMediaComponentResponse } from "@customTypes/componentResponses";
import Head from "next/head";

interface IOpenGraphImage {
    image: string | IMediaComponentResponse | undefined | IImageComponentResponse;
    alt?: string;
}

const OpenGraphImage = ({image, alt}: IOpenGraphImage): JSX.Element => {
    return (<Head>
        <meta property="og:image" content={ImageDefault(image)}/>
        <meta property="og:image:alt" content={alt || ""}/>
    </Head>);
}

export default OpenGraphImage;
