import Chip from "@components/SearchManagement/Chip";
import CustomHeading from "@components/common/CustomHeading";
import GradientCover from "@components/common/GradientCover";
import ImageCDN from "@components/skeleton/ImageCDN";
import { ImageDefault } from "@components/utils/Utils";
import { IImageComponentResponse } from "@customTypes/componentResponses";
import { ITagResponse } from "@customTypes/modelResponses";
import Link from "next/link";

interface ITCBioCard {
  image?: IImageComponentResponse | null;
  name?: string;
  slug?: string;
  blurb?: string;
  specialties?: ITagResponse[];
}

const TCBioCard = ({
                     image,
                     name,
                     slug,
                     blurb,
                     specialties,
                   }: ITCBioCard): JSX.Element => {

  return (
    <div className="relative h-[420px] w-full overflow-hidden rounded-3xl">
      <Link
        href={`/our-travel-agency/planners/${slug}`}
        prefetch={false}
      >
        <div className="relative block h-[300px] w-full">
          <GradientCover/>
          <ImageCDN
            className="z-[0]"
            src={ImageDefault(image)}
            width={300}
            height={300}
            layout="fill"
            objectFit="cover"
            alt="w"
            priority={false}
          />
          <CustomHeading
            headingLevel="h3"
            className="absolute bottom-[0.4rem] z-[2] px-5 text-h6 font-medium text-white"
          >
            {name}
          </CustomHeading>
        </div>
        <div className="absolute bottom-0 h-[120px] w-full bg-black px-5">
          {specialties && (
            <div className="no-scrollbar flex max-h-[30px] flex-wrap gap-1 overflow-hidden">
              {specialties?.slice(0, 3).map((specialty) => {
                if (specialty.name) {
                  return (
                    <Chip
                      key={specialty.id}
                      item={{
                        title: specialty.name,
                      }}
                      variant="white"
                      onClick={undefined}
                      cursorPointer
                    />
                  );
                }
                return <></>;
              })}
            </div>
          )}
          {blurb && (
            <p className="absolute bottom-5 mr-5 text-p text-lgray line-clamp-3">
              {blurb}
            </p>
          )}
        </div>
      </Link>
    </div>
  );
};

export default TCBioCard;
